import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { FaLaughWink } from "react-icons/fa"

const NotFoundPage = () => {
  return (
    <React.Fragment>
      <SEO title="Oops, Page not found" />
      <Layout>
        <div className="container py-5">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <FaLaughWink size={`10rem`} style={{ color: "#1E5591" }} />
            <div className="my-3" />
            <h1 className="display-4 text-center" style={{ color: "#1E5591" }}>
              Oops, we haven't made this page yet.
            </h1>
            <div className="my-3" />
            <p className="text-center lead text-muted">
              If you're interested to see this page, why don't we discuss how we
              can work together?
            </p>
            <a
              className="text-decoration-none"
              style={{ color: "#1E5591" }}
              href="https://wa.me/628888344735"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Ricardo Sawir
            </a>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default NotFoundPage
