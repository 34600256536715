import React from "react"
import Navbar from "./navbar/navbar"
import Footer from "./footer/footer"

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <div
        className="d-flex flex-column"
        style={{ minHeight: "100vh", fontFamily: "Titillium Web, sans-serif" }}
      >
        <Navbar />
        <div className="space-navbar" />
        {children}
        <div className="flex-grow-1" />
        <Footer />
      </div>
    </React.Fragment>
  )
}
export default Layout
