import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const Footer = () => {
  const { logo } = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "Institut-Teknologi-Bandung-icon.png" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <div className="py-5" style={{ backgroundColor: "#1E5591" }}>
        <div className="container">
          <div className="d-flex flex-wrap flex-column flex-md-row align-items-md-center justify-content-between">
            <Link
              to="/"
              className="text-decoration-none d-flex align-items-center"
            >
              <Img fixed={logo.childImageSharp.fixed} />
              <span className="ml-2 text-light">DKV ITB</span>
            </Link>
            <ul className="d-flex flex-column flex-md-row mt-3 mx-0 px-0 list-unstyled">
              <li className="mt-4 mt-md-0 mx-0 mx-md-2">
                <Link
                  className="text-light text-decoration-none"
                  to="/about-dkv-itb"
                >
                  About DKV ITB
                </Link>
              </li>
              <li className="mt-4 mt-md-0 mx-0 mx-md-2">
                <Link
                  className="text-light text-decoration-none"
                  to="/academic-programs"
                >
                  Academic Programs
                </Link>
              </li>
              <li className="mt-4 mt-md-0 mx-0 mx-md-2">
                <Link className="text-light text-decoration-none" to="/alumni">
                  Alumni
                </Link>
              </li>
              <li className="mt-4 mt-md-0 mx-0 mx-md-2">
                <Link
                  className="text-light text-decoration-none"
                  to="/news-opportunities"
                >
                  News & Opportunities
                </Link>
              </li>
            </ul>
          </div>
          <p className="text-light my-4" style={{ maxWidth: "350px" }}>
            Jalan Ganesha No.10, Lebak Siliwangi, Coblong, Bandung, Jawa Barat
            40132, Indonesia
          </p>
          <a
            href="https://goo.gl/maps/Sj8WTSW5nQrbnHE99"
            target="_blank"
            rel="noopener noreferrer"
            className="text-light text-decoration-none"
          >
            Lihat di Map
          </a>
          <p className="text-light mt-5" style={{ maxWidth: "350px" }}>
            Phone:{" "}
            <a
              href="tel:0222500935"
              className="text-light text-decoration-none"
            >
              +6222 2500935
            </a>
          </p>
          <p className="text-light" style={{ maxWidth: "350px" }}>
            E-mail:{" "}
            <a
              href="mailto:info@dkv-itb.ac.id"
              className="text-light text-decoration-none"
            >
              info@dkv-itb.ac.id
            </a>
          </p>
          <div className="my-5 " />
          <p className="text-center text-light m-0 p-0">
            ⓒ {new Date().getFullYear().toString()} DKV ITB. All Rights
            Reserved.
          </p>
          <p className="text-center text-light">
            Built with{" "}
            <a
              href="https://sawirstudio.com"
              className="text-light text-decoration-none"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sawir Studio
            </a>
          </p>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Footer
