import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { MdMenu } from "react-icons/md"

const Navbar = () => {
  const [openNavbar, setOpenNavbar] = useState(false)
  const [openDropdownAcademic, setOpenDropdownAcademic] = useState(false)
  const { logoITB } = useStaticQuery(graphql`
    {
      logoITB: file(
        relativePath: { eq: "Institut-Teknologi-Bandung-icon.png" }
      ) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top shadow">
        <Link to="/" className="navbar-brand d-flex align-items-center">
          <Img fixed={logoITB.childImageSharp.fixed} />
          <span className="ml-2" style={{ color: "#1E5591" }}>
            DKV ITB
          </span>
        </Link>
        <button
          onClick={() => setOpenNavbar(!openNavbar)}
          className="navbar-toggler border-0"
          type="button"
        >
          <MdMenu style={{ color: "#1E5591" }} />
        </button>
        <div
          className={`collapse navbar-collapse ${openNavbar &&
            `show`} justify-content-end`}
        >
          <ul className="navbar-nav ml-auto align-items-end">
            <li className="nav-item">
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about-dkv-itb">
                About DKV ITB
              </Link>
            </li>
            <li
              className={`nav-item dropdown ${openDropdownAcademic && `show`}`}
              onClick={() => setOpenDropdownAcademic(!openDropdownAcademic)}
            >
              <Link className="nav-link dropdown-toggle">
                Academic Programs
              </Link>
              <div
                className={`dropdown-menu ${openDropdownAcademic && `show`}`}
              >
                <Link
                  to="/academic-programs/komunikasi-grafis"
                  className="dropdown-item"
                >
                  Komunikasi Grafis
                </Link>
                <Link
                  to="/academic-programs/komunikasi-multimedia"
                  className="dropdown-item"
                >
                  Komunikasi Multimedia
                </Link>
                <Link
                  to="/academic-programs/komunikasi-periklanan"
                  className="dropdown-item"
                >
                  Komunikasi Periklanan
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/alumni">
                Alumni
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/news-opportunities">
                News & Opportunities
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </React.Fragment>
  )
}
export default Navbar
